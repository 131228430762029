import tippy from 'tippy.js';

const tooltips = () => {
    tippy('.header__right .btn_pencil',{
        placement: 'bottom-end',
        maxWidth: 255,
        offset: [-60,20]
    });
    tippy('.btn-tooltip',{
        placement: 'top-start',
        // maxWidth: 255,
        // offset: [-60,20]
    });
};

tooltips();