import Swiper, {
    Navigation,
    Lazy,
    EffectCreative,
    Pagination,
    Controller,
    Autoplay
} from "swiper";
import { sliceToLetters } from "./slice-to-letters";
// import {gsap} from "gsap";

Swiper.use([Navigation, Pagination, Lazy, EffectCreative, Autoplay]);

const parent = document.querySelector(".inspiration-slider__block");
const titles = document.querySelectorAll(
    ".inspiration-slider-description__item"
);
const pencilTitles = document.querySelectorAll(
    ".inspiration-slider-pencil__item"
);
const sliderTexts = document.querySelectorAll(
    ".inspiration-slider-description__item .top,.inspiration-slider-description__item .bottom"
);
const pencilTitlesText = document.querySelectorAll(
    ".inspiration-slider-pencil__item .left, .inspiration-slider-pencil__item .right"
);
let activeSlide = 0;

if (titles.length > 0) {
    sliceToLetters(sliderTexts);
    [...titles].forEach((e, i) => {
        e.dataset.index = i;
    });
}
if (pencilTitles.length > 0) {
    sliceToLetters(pencilTitlesText);
    [...pencilTitles].forEach((e, i) => {
        e.dataset.index = i;
    });

}

if(parent?.dataset?.activeSlide){
    activeSlide = Number(parent.dataset.activeSlide);
}

const fullImg = new Swiper(".inspiration-linked-slider", {
    modules: [Controller],
    preloadImages: false,
    lazy: true,
    loop: true,
    loopAdditionalSlides: 1,
    slidesPerView: 1,
    speed: 1000,
});

const swiper = new Swiper(".inspiration-slider", {
    modules: [Controller],
    preloadImages: false,
    lazy: {
        loadPrevNext: true,
      	loadPrevNextAmount: 2,
    },
    autoplay: {
        delay: 3000,
    },
    loop: true,
    loopAdditionalSlides: 1,
    slidesPerView: 1,
    initialSlide: activeSlide,
    speed: 1000,
    spaceBetween: 15,
    grabCursor: true,
    effect: "creative",
    creativeEffect: {
        prev: {
            shadow: false,
            translate: [0, 0, 100],
        },
        next: {
            translate: ["-30%", 0, 0],
        },

    },

    pagination: {
        el: ".swiper-pagination",
        type: "fraction",
    },
    navigation: {
        nextEl: ".inspiration-slider__arrow-next",
        prevEl: ".inspiration-slider__arrow-prev",
    },
    breakpoints: {
        320: {
            spaceBetween: 0,
        },
        992: {
            spaceBetween: 15,
        },
    },
    on: {
        init: function () {
            if(pencilTitles.length > 0 && titles.length > 0){
                setTimeout(() => {
                    document.querySelector('.inspiration-slider-pencil').classList.add('is-visible');
                    document.querySelector('.inspiration-slider-description').classList.add('is-visible');
                }, 300);
            }
        },
        slideChangeTransitionStart(el) {
            if (titles.length > 0) {
                const activeTitle = document.querySelector(
                    ".inspiration-slider-description__item.is-active"
                );
                // console.log('dd');
                if (!!activeTitle) {
                    activeTitle.classList.remove("is-active");
                }
            }

            if (pencilTitles.length > 0) {
                const activeTitle = document.querySelector(
                    ".inspiration-slider-pencil__item.is-active"
                );
                if (!!activeTitle) {
                    activeTitle.classList.remove("is-active");
                }
            }

        },
        slideChangeTransitionEnd(el) {
            if (titles.length > 0) {
                [...el.slides].forEach((slide) => {
                    if (slide.classList.contains("swiper-slide-active")) {
                        const index = slide.dataset.swiperSlideIndex;
                        [...titles][index].classList.add("is-active");
                        if (pencilTitles.length > 0) {
                            [...pencilTitles][index].classList.add("is-active");
                        }
                    }
                });
            }

        },
    },
});

// if(parent?.dataset?.type === 'linked'){
//     swiper.controller.control = fullImg;
//     fullImg.controller.control = swiper;
// }

