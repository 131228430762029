import { wrapDrawedImagesWithBox } from './wrap-drawed-image-with-box';
import { splitBlob } from "./split-blob";

const axios = require("axios");

const regenerateHandle =
    /**
     * @param {Event} event
     */
      async (event) => {
          event.preventDefault();

          /** @type {HTMLButtonElement|null} */
          const element = event.target;
          if (!element) {
              return;
          }

          /** @type {HTMLFormElement|null} */
          const form = element.closest('form');
          if (!form) {
              return null;
          }

          /** @type {HTMLTextAreaElement|null} */
          const idsElement = form.querySelector('[name="ids"]');
          if (!idsElement) {
              return null;
          }

          const ids = [' ', '\n']
                .reduce((acc, separator) => acc.flatMap((it) => it.split(separator)), [idsElement.value])
                .filter(id => id !== "");

          try {
              await Promise.all(
                  ids.map(async (id) => {
                      /** @type {HTMLDivElement} */
                      const container = document.createElement('div');
                      container.innerHTML = await (await (await fetch(`/image/${id}/raw`)).blob()).text();
                      /** @type {SVGElement} */
                      const svgElement = container.querySelector('svg');

                      const { drawedImageInsta, drawedImage, drawedImageWithBox, drawedImageFb, drawedImageStory } = await wrapDrawedImagesWithBox(svgElement);

                      await Promise.all(
                          [
                              ['preview', drawedImageWithBox],
                              ['without-box', drawedImage],
                              ['square', drawedImageInsta],
                              ['story', drawedImageStory],
                              ['facebook', drawedImageFb]
                          ]
                              .map(([type, blob]) => new Promise(async (resolve, reject) => {
                                  const blobs = splitBlob(blob, 0, 1024 * 1024 * 9 /* 9 megabytes */);

                                  try {
                                      let isFirst = true;
                                      for (const blob of blobs) {
                                          const formData = new FormData();
                                          formData.append('file', blob);

                                          await axios({
                                              method: "post",
                                              url: `/image/upload-image/${id}/${type}?rewrite=${isFirst ? 1 : 0}`,
                                              data: formData,
                                              headers: {
                                                  'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                                              },
                                          });

                                          isFirst = false;
                                      }
                                      resolve();
                                  } catch {
                                      reject()
                                  }
                              }))
                      );

                  })
              );
              alert('successful');
          } catch {
              alert('failed');
          }
    }

const element = document.querySelector('.app-regenerate');
if (element) {
    element.addEventListener('click', regenerateHandle);
}
