// import './components/canvas__(grid)';
// import  './components/scrollspy';
// import './components/formComponent';
// import textFit from 'textfit';
import './components/throttle';


import  './components/coloringAndSave';
// import  './components/variant2';


// import './components/canvas-first-screen';
import './components/color-full-dd';


import './components/--animations';
import './components/scrollTo';
import './components/tooltips';

import './components/slider';
import './components/regenerate';
// import './components/waves';

import SmoothScrollMagic from './components/smoothScroll';

let smoothScrollAllPage = new SmoothScrollMagic;





const layout = document.querySelector('.layout');
const pageY = () => {
    return window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
}



smoothScrollAllPage.init();
if(!layout.classList.contains('removeSmoothScroll')){
    window.addEventListener("optimizedScroll", function() {
        if (pageY() > 10) {
            document.body.classList.add("is-scrolled");
        } else {
            document.body.classList.remove("is-scrolled");
        }

        setTimeout(() => {
            headerHeight();
        }, 100);
        // console.log(document.getElementById('header').offsetHeight);
    });


}
let isTouch = false;
if ('ontouchstart' in document.documentElement) {
    isTouch = true;
}
document.body.className += isTouch ? ' touch' : ' no-touch';






//btn pencil animation
const btnPencil = document.querySelectorAll('.btn.btn_pencil');

if(!!btnPencil){
    [...btnPencil].forEach(e=>{
        const   inner = e.querySelector('.text'),
                content = inner.innerHTML;
        inner.setAttribute('data-content',content);
    })
}

window.addEventListener("optimizedResize", function() {
    headerHeight();
});

function toggle(selector,cls){
    let target = document.querySelectorAll(selector);
    if(target){
        target.forEach(text => {
            text.addEventListener('click', function(){
                this.classList.toggle(cls);
            })
        })
    }
}
//
// toggle('.header__trigger','is-open');


//example footer height detect
// function _getAbsoluteHeight(el) {
//     el = (typeof el === 'string') ? document.querySelector(el) : el;
//     var styles = window.getComputedStyle(el);
//     var margin = parseFloat(styles['marginTop']) +
//                  parseFloat(styles['marginBottom']);
//     return Math.ceil(el.offsetHeight + margin);
// }

// //footer height variable
// function setVariableFooterHeight(){
//     setTimeout(() => {
//         const footerH = _getAbsoluteHeight('.footer');

//         if(footerH !== 0){
//             document.documentElement.style.setProperty('--footerHeight', footerH + 'px');
//         }

//     }, 200);
// }

// setVariableFooterHeight();

// window.addEventListener("orientationchange", function() {
//     setVariableFooterHeight();
// });




// Mob  menu
(function () {
    let trigger = document.querySelector('.js-menu-trigger');
    if (trigger) {
        trigger.onclick = () => {
            document.body.classList.toggle('menu-open');
        };
    }
})();


// Modal menu
(function () {
    const triggerModal = document.querySelector('[data-modal]');
    if (!!triggerModal) {
        const id = triggerModal.dataset.modal;
        const modal = document.querySelector('#'+id);
        if(!!modal){
            const closeModal = modal.querySelectorAll('[aria-label="Close"]');
            triggerModal.onclick = () => {
                document.body.classList.toggle('show-modal');
                modal.classList.toggle('is-open');
            };
            closeModal.forEach(e => {
                e.onclick = () => {
                    document.body.classList.remove('show-modal');
                    document.body.classList.remove('show-modal-confirm');
                    document.body.classList.remove('show-modal-coloring');
                    document.body.classList.remove('show-modal-info');
                    modal.classList.toggle('is-open');
                };
            })

        }
    }
})();
