/**
 * @param {Blob} imageBlob
 * @param {number} width
 * @param {number} height
 * @param {number} canvasiWidth
 * @param {number} canvasHeight
 * @param {number} rotate
 * @return {Promise<Blob>}
 */
const rotateImage = async (imageBlob, width, height, canvasWidth, canvasHeight, rotate) => {
    const imageURL = URL.createObjectURL(imageBlob);
    const image = new Image();
    const imageLoadHandle = new Promise((resolve) => {
        image.onload = () => {
            URL.revokeObjectURL(imageURL);
            resolve();
        }
    });

    image.src = imageURL;

    await imageLoadHandle;

    /** @type {HTMLCanvasElement} */
    const canvas = document.createElement('canvas');

    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    /** @type {CanvasRenderingContext2D} */
    const ctx = canvas.getContext('2d');

    ctx.drawImage(image, 0, 0, width, height);

    return await new Promise((resolve) => {
        canvas.toBlob((resultBlob) => {
            resolve(resultBlob);
            canvas.remove();
        });
    });
}

export const wrapDrawedImagesWithBox =
    /**
     * @param {SVGElement} drawedImageSVG
     * @return {Promise<{drawedImage: Blob; drawedImageWithBox: Blob; drawedImageInsta: Blob; drawedImageFb: Blob; drawedImageStory: Blob; fileName: String}>}
     */
    //  DESIGN+ankatrien+01+milka_belgium.pdf
    // 1149/653 result img => .5683
    async (drawedImageSVG) => {
        const LANG = document.documentElement.lang || 'nl';
        const userName = document.getElementsByName("instagram-name")[0]?.value || "";
        const name = userName;
        const urlParams = new URLSearchParams(window.location.search);
        const stringToPencil = name + ' ' + (urlParams.get('artist') || "").replace(/\d+/g, '');
        const colorText = "#493280";
        const artistName = (urlParams.get('artist') || "").replace(/\@/g, '');
        const artistNameToFileName  = {
            'milka_belgium1' : "+01+milka_belgium",
            'milka_belgium2' : "+02+milka_belgium",
            'milka_belgium3' : "+03+milka_belgium",
            'stefani servaege'  : "+04+stefani_servaege",
            'jasper van gestel' : "+05+jaspervangestel",
            'dirty belgium'     : "+06+dirtybelgium",
            'milka_belgium4' : "+07+milka_belgium",
            'milka_belgium5' : "+08+milka_belgium",
            'milka_belgium6' : "+09+milka_belgium",
            'marlies bruynseels' : "+10+marlies.bruynseels",
            'dekwekerij_studio' : "+11+dekwekerij_studio",
            'michele vanparys'  : "+12+michele_vanparys",
            'studio_nogo'       : "+13+studio_nogo",
            'milka_belgium7' : "+14+milka_belgium",
            'milka_belgium8' : "+15+milka_belgium",
            'milka_belgium9' : "+16+milka_belgium",
            'meneer heirman'    : "+17+meneerheirman",
            'jonas sysmans'     : "+18+jonassysmans",
            'freya pede'        : "+19+freya.pede",
            'milka_belgium10' : "+20+milka_belgium",
            'milka_belgium11' : "+21+milka_belgium",
            'reyes alvarez'     : "+22+hey.reyes.hey",
            'milka_belgium12' : "+23+milka_belgium",
            'alexander hellebaut' : "+24+alexanderhellebaut"
        }

        const fileName = 'DESIGN+' + userName.replace(/\s/g, '_') + artistNameToFileName[artistName] + '.pdf';

        console.log(stringToPencil);
        console.log(fileName);

        const drawedImageWidthInBox = 940;
        const drawedImageHeightInBox = 534;
        const drawedImagePositionXInBox = 997;
        const drawedImagePositionYInBox = 0;
        const boxWidth = 1937;
        const boxHeight = 534;
        const pencilTextFont = "30px Cardenio Modern";
        const pencilTextAngle = (3.6 * Math.PI) / 180;
        const pencilTextPositionX = 360;
        const pencilTextPositionY = 431;
        const pencilTextMaxWidth = 525;


        //insta
        const drawedImageWidthInBoxInstagram = 510;
        const drawedImageHeightInBoxInstagram = 290;
        const angle = (-6.5 * Math.PI) / 180;
        const boxInstagramWidth = 1000;
        const boxInstagramHeight = 1000;
        //510*290
        const pencilInstagramTextFont = "14px Cardenio Modern";
        const pencilInstagramTextAngle = (-6.7 * Math.PI) / 180;
        const pencilInstagramTextPositionX = 233;
        const pencilInstagramTextPositionY = 549;
        const pencilInstagramTextMaxWidth = 225;


        //instaStory
        const drawedImageWidthInBoxStory = 330;
        const drawedImageHeightInBoxStory = 188;
        const drawedImagePositionYInBoxStory = 325;
        const angleStory = (-7.2 * Math.PI) / 180;
        const boxStoryWidth = 612;
        const boxStoryHeight = 1089;
        //510*290
        const pencilStoryTextFont = "10px Cardenio Modern";
        const pencilStoryTextAngle = (-6.7 * Math.PI) / 180;
        const pencilStoryTextPositionX = 144;
        const pencilStoryTextPositionY = 532;
        const pencilStoryTextMaxWidth = 140;


        //fb
        const drawedImageWidthInBoxFacebook = 420;
        const drawedImageHeightInBoxFacebook = 240;
        const drawedImagePositionXInBoxFb = 810;
        const drawedImagePositionYInBoxFb = 146;
        const boxFacebookWidth = 1200;
        const boxFacebookHeight = 630;
        //510*290
        const pencilFbTextFont = "14px Cardenio Modern";
        const pencilFbTextAngle = (-6.7 * Math.PI) / 180;
        const pencilFbTextPositionX = 193;
        const pencilFbTextPositionY = 424;
        const pencilFbTextMaxWidth = 185;


        // const

        /** @type {SVGElement} */
        const clonedSVGElement = drawedImageSVG.cloneNode(true);
        /** @type {SVGElement} */
        const clonedSVGElementForInsta = drawedImageSVG.cloneNode(true);
        /** @type {SVGElement} */
        const clonedSVGElementForStory = drawedImageSVG.cloneNode(true);
        /** @type {SVGElement} */
        const clonedSVGElementForFb = drawedImageSVG.cloneNode(true);
        /** @type {SVGElement} */
        const clonedSVGElementForPrint = drawedImageSVG.cloneNode(true);

        clonedSVGElement.setAttribute("width", drawedImageWidthInBox);
        clonedSVGElement.setAttribute("height", drawedImageHeightInBox);

        clonedSVGElementForInsta.setAttribute(
            "width",
            drawedImageWidthInBoxInstagram
        );
        clonedSVGElementForInsta.setAttribute(
            "height",
            drawedImageHeightInBoxInstagram
        );

        clonedSVGElementForStory.setAttribute(
            "width",
            drawedImageWidthInBoxStory
        );
        clonedSVGElementForStory.setAttribute(
            "height",
            drawedImageHeightInBoxStory
        );

        clonedSVGElementForFb.setAttribute(
            "width",
            drawedImageWidthInBoxFacebook
        );
        clonedSVGElementForFb.setAttribute(
            "height",
            drawedImageHeightInBoxFacebook
        );

        const printImageWidth = 2441;
        const printImageHeight = 1392;
        clonedSVGElementForPrint.setAttribute("width", printImageWidth);
        clonedSVGElementForPrint.setAttribute("height", printImageHeight);

        const drawedImage = new Blob([clonedSVGElement.outerHTML], {
            type: "image/svg+xml",
        });

        const drawedImageRaw = new Blob([drawedImageSVG.outerHTML], {
            type: "image/svg+xml",
        });

        const drawedImagePrintRawBlob = new Blob([clonedSVGElementForPrint.outerHTML], {
            type: "image/svg+xml",
        });

        const drawedImagePrintBlob = await rotateImage(drawedImagePrintRawBlob, printImageWidth, printImageHeight, 2422, 1378, 0);

        const drawedImageInstaBlob = new Blob(
            [clonedSVGElementForInsta.outerHTML],
            {
                type: "image/svg+xml",
            }
        );

        const drawedImageFbBlob = new Blob(
            [clonedSVGElementForFb.outerHTML],
            {
                type: "image/svg+xml",
            }
        );
        const drawedImageStoryBlob = new Blob(
            [clonedSVGElementForStory.outerHTML],
            {
                type: "image/svg+xml",
            }
        );

        const drawedImageURL = URL.createObjectURL(drawedImage);
        const boxImageURL = "/img/box/factory/box_src.png";

        const drawedImageInstaURL = URL.createObjectURL(drawedImageInstaBlob);
        const boxImageInsta = `/img/box/factory/box-insta-square_v1_${LANG}.png`;

        const drawedImageFbURL = URL.createObjectURL(drawedImageFbBlob);
        const boxImageFb = `/img/box/factory/box-facebook_${LANG}.png`;

        const drawedImageStoryURL = URL.createObjectURL(drawedImageFbBlob);
        const boxImageStory = `/img/box/factory/box-insta-story_v2_${LANG}.png`;

        /** @type {HTMLCanvasElement} */
        const canvas = document.createElement("canvas");
        /** @type {CanvasRenderingContext2D} */
        const ctx = canvas.getContext("2d");

        canvas.width = boxWidth;
        canvas.height = boxHeight;

        /** @type {HTMLCanvasElement} */
        const canvasInsta = document.createElement("canvas");
        /** @type {CanvasRenderingContext2D} */
        const ctxInsta = canvasInsta.getContext("2d");

        canvasInsta.width = boxInstagramWidth;
        canvasInsta.height = boxInstagramHeight;

        /** @type {HTMLCanvasElement} */
        const canvasFb = document.createElement("canvas");
        /** @type {CanvasRenderingContext2D} */
        const ctxFb = canvasFb.getContext("2d");

        canvasFb.width = boxFacebookWidth;
        canvasFb.height = boxFacebookHeight;

        /** @type {HTMLCanvasElement} */
        const canvasStory = document.createElement("canvas");
        /** @type {CanvasRenderingContext2D} */
        const ctxStory = canvasStory.getContext("2d");

        canvasStory.width = boxStoryWidth;
        canvasStory.height = boxStoryHeight;

        const boxImg = new Image();
        const drawedImg = new Image();
        const boxInstaImg = new Image();
        const drawedInstaImg = new Image();
        const boxFbImg = new Image();
        const drawedFbImg = new Image();
        const boxStoryImg = new Image();
        const drawedStoryImg = new Image();

        const loadImages = Promise.all(
            [
                [boxImg, boxImageURL],
                [drawedImg, drawedImageURL],
                [boxInstaImg, null],
                [drawedInstaImg, drawedImageInstaURL],
                [boxFbImg, null],
                [drawedFbImg, drawedImageFbURL],
                [boxStoryImg, null],
                [drawedStoryImg, drawedImageStoryURL],
            ].map(
                ([img, url]) =>
                    new Promise((resolve, _reject) => {
                        img.onload = () => {
                            if (url) {
                                URL.revokeObjectURL(url);
                            }
                            resolve();
                        };
                    })
            )
        );

        boxImg.src = boxImageURL;
        drawedImg.src = drawedImageURL;
        boxInstaImg.src = boxImageInsta;
        drawedInstaImg.src = drawedImageInstaURL;
        boxFbImg.src = boxImageFb;
        drawedFbImg.src = drawedImageFbURL;
        boxStoryImg.src = boxImageStory;
        drawedStoryImg.src = drawedImageStoryURL;

        await loadImages;

        ctx.drawImage(
            drawedImg,
            drawedImagePositionXInBox,
            drawedImagePositionYInBox,
            drawedImageWidthInBox,
            drawedImageHeightInBox
        );

        ctx.drawImage(boxImg, 0, 0, boxWidth, boxHeight);

        ctx.save()
        // let ctxText = ctx.measureText(stringToPencil);
        // let actualPencilTextPositionX = pencilTextPositionX + pencilTextMaxWidth / 2 - ctxText.width / 2;

        // let widthLegA = actualPencilTextPositionX - pencilTextPositionX;
        // let widthLegB = Math.tan(pencilTextAngle) * widthLegA;

        // ctx.translate(actualPencilTextPositionX,pencilTextPositionY + widthLegB)
        ctx.translate(pencilTextPositionX + pencilTextMaxWidth / 2, pencilTextPositionY)
        ctx.rotate(pencilTextAngle);
        ctx.fillStyle = colorText;
        ctx.textAlign = 'center';
        ctx.font = pencilTextFont;
        ctx.fillText(stringToPencil, 0, 0, pencilTextMaxWidth);
        ctx.restore();
        // pencilTextFont



        /**
         * @type {Blob}
         */
        const drawedImageWithBox = await new Promise((resolve, _reject) => {
            canvas.toBlob(
                (result) => {
                    canvas.remove();
                    drawedImg.remove();
                    boxImg.remove();

                    resolve(result);
                },
                "image/jpeg",
                0.7
            );
        });

        //preview for instagram
        ctxInsta.save();
        ctxInsta.translate(boxInstagramWidth, boxInstagramHeight / 2);
        ctxInsta.rotate(angle);
        ctxInsta.drawImage(
            drawedInstaImg,
            -drawedImageWidthInBoxInstagram + 20,
            -drawedImageHeightInBoxInstagram,
            drawedImageWidthInBoxInstagram,
            drawedImageHeightInBoxInstagram
        );

        ctxInsta.restore();

        ctxInsta.drawImage(
            boxInstaImg,
            0,
            0,
            boxInstagramWidth,
            boxInstagramHeight
        );

        ctxInsta.save()
        ctxInsta.translate(pencilInstagramTextPositionX + pencilInstagramTextMaxWidth / 2, pencilInstagramTextPositionY)
        ctxInsta.rotate(pencilInstagramTextAngle);
        ctxInsta.fillStyle = colorText;
        ctxInsta.textAlign = 'center';
        ctxInsta.font = pencilInstagramTextFont;
        ctxInsta.fillText(stringToPencil, 0, 0, pencilInstagramTextMaxWidth);
        ctxInsta.restore();


        const drawedImageInsta = await new Promise((resolve, _reject) => {
            canvasInsta.toBlob(
                (result) => {
                    canvasInsta.remove();
                    drawedInstaImg.remove();
                    boxInstaImg.remove();

                    resolve(result);
                },
                "image/jpeg",
                0.7
            );
        });

        //preview for Story instagram
        ctxStory.save();
        ctxStory.translate(boxStoryWidth, drawedImagePositionYInBoxStory);
        ctxStory.rotate(angleStory);
        ctxStory.drawImage(
            drawedStoryImg,
            -drawedImageWidthInBoxStory - 10,
            0,
            drawedImageWidthInBoxStory,
            drawedImageHeightInBoxStory
        );

        ctxStory.restore();

        // ctxStory.save();
        // ctxStory.globalAlpha = 0.4;
        ctxStory.drawImage(
            boxStoryImg,
            0,
            0,
            boxStoryWidth,
            boxStoryHeight
        );
        // ctxStory.restore();

        ctxStory.save()
        ctxStory.translate(pencilStoryTextPositionX + pencilStoryTextMaxWidth / 2, pencilStoryTextPositionY)
        ctxStory.rotate(pencilStoryTextAngle);
        ctxStory.fillStyle = colorText;
        ctxStory.textAlign = 'center';
        ctxStory.font = pencilStoryTextFont;
        ctxStory.fillText(stringToPencil, 0, 0, pencilStoryTextMaxWidth);
        ctxStory.restore();


        const drawedImageStory = await new Promise((resolve, _reject) => {
            canvasStory.toBlob(
                (result) => {
                    canvasStory.remove();
                    drawedStoryImg.remove();
                    boxStoryImg.remove();

                    resolve(result);
                },
                "image/jpeg",
                0.7
            );
        });

        //preview for fb
        ctxFb.save();
        ctxFb.translate(drawedImagePositionXInBoxFb, drawedImagePositionYInBoxFb);
        ctxFb.rotate(angle);
        ctxFb.drawImage(
            drawedFbImg,
            -drawedImageWidthInBoxFacebook + 20,
            0,
            drawedImageWidthInBoxFacebook,
            drawedImageHeightInBoxFacebook
        );

        ctxFb.restore();

        ctxFb.drawImage(
            boxFbImg,
            0,
            0,
            boxFacebookWidth,
            boxFacebookHeight
        );

        ctxFb.save()
        ctxFb.translate(pencilFbTextPositionX + pencilFbTextMaxWidth / 2, pencilFbTextPositionY)
        ctxFb.rotate(pencilFbTextAngle);
        ctxFb.fillStyle = colorText;
        ctxFb.textAlign = 'center';
        ctxFb.font = pencilFbTextFont;
        ctxFb.fillText(stringToPencil, 0, 0, pencilFbTextMaxWidth);
        ctxFb.restore();

        const drawedImageFb = await new Promise((resolve, _reject) => {
            canvasFb.toBlob(
                (result) => {
                    canvasFb.remove();
                    drawedFbImg.remove();
                    boxFbImg.remove();

                    resolve(result);
                },
                "image/jpeg",
                0.7
            );
        });

        return { drawedImageInsta, drawedImageRaw, drawedImage: drawedImagePrintBlob, drawedImageWithBox, drawedImageFb, drawedImageStory, fileName };
    };
