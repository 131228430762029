document.querySelectorAll('.js-scrollBtn, .legal-page .navlink, .col-tab-section a.btn, .btn-policy-top-middle').forEach(link => {

    link.addEventListener('click', function (e) {
        e.preventDefault();

        let href = this.getAttribute('href').substring(1);

        const scrollTarget = document.getElementById(href) || document.querySelector(`[name="${href}"]`),
            elementPosition = scrollTarget.getBoundingClientRect().top,
            offsetPosition = elementPosition - 150;

        window.scrollBy({
            top: offsetPosition,
            behavior: 'smooth'
        });
    });
});