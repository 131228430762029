import { wrapDrawedImagesWithBox } from "./wrap-drawed-image-with-box";
import { isLocal } from "./services";
import { splitBlob } from "./split-blob";
import interact from 'interactjs';

const axios = require("axios");

const coloring = document.querySelector(".js-canvas-wrapper");

const formAgreementInputs = document.querySelectorAll(
    "#agreementTerms"
);

if (!!coloring) {
    draw();
}

function draw() {
    let color = "#000000";
    let colors = [
        "#D3D1E9",
        "#A39DCD",
        "#766BB0",
        "#392C7C",
        "#B8E5FA",
        "#7FCFF4",
        "#33A9D7",
        "#B8D980",
        "#A3C13F",
        "#FBDCE1",
        "#F176AF",
        "#FDBF12",
    ];

    const svgObj = coloring.querySelector(".js-canvas-inner svg"),
        svgPath = svgObj.querySelectorAll("path"),
        clr = document.querySelector(".js-btn-reset"),
        pickers = document.querySelectorAll(".js-palette .palette__item");
    const cursor = document.querySelector(".js-cursor"),
        parent = document.querySelector(".cnv");

    let positionParent = parent.getBoundingClientRect();

    if (pickers.length > 0) {
        colors = [];
    }
    pickers.forEach((e) => {
        colors.push(e.dataset.color);
        e.addEventListener("click", watchColorPicker, false);
    });

    function setDefColor() {
        let defColor =
            getComputedStyle(coloring).getPropertyValue("--selected-color");
        // console.log(defColor);
        if (defColor.length) {
            color = defColor;
        }
    }
    setDefColor();

    function watchColorPicker(event) {
        [...pickers]
            .filter((el) => el !== event.target)
            .forEach((el) => {
                el.classList.remove("palette__item_is-active");
            });
        event.target.classList.add("palette__item_is-active");

        coloring.style.setProperty(
            "--selected-color",
            event.target.dataset.color
        );
        color = event.target.dataset.color;
    }

    clr.addEventListener("click", function () {
        for (let i = 0; i < svgPath.length; i++) {
            const path = svgPath[i];
            path.classList.remove("is-selected");
            path.style.fill = path.dataset.startColor || "#000000";
        }
    });

    //on ready img
    svgPath.forEach((e) => {
        // console.log(window.getComputedStyle(e, null).getPropertyValue("fill"))
        const fill = window.getComputedStyle(e, null).getPropertyValue("fill");
        if (fill) {
            e.dataset.startColor = fill;
            if (fill === "rgb(255, 255, 255)" || fill === "#FFFFFF") {
                e.classList.add("can-colored");
            }
        }
    });

    svgObj.addEventListener("click", function (e) {
        if (e.target.classList.contains("can-colored")) {
            e.target.style.fill = color;
        }
    });

    if (!!cursor) {
        window.addEventListener("scroll", function (e) {
            positionParent = parent.getBoundingClientRect();
            // console.log(positionParent);
        });
        parent.addEventListener("mouseenter", function (e) {
            onMouseEnter(e);
        });
        window.addEventListener("mousemove", onMouseMove);
        parent.addEventListener("mouseleave", function (e) {
            onMouseLeave(e);
        });
    }

    function onMouseEnter(e) {
        parent.classList.add("cursor-off");
    }
    function onMouseMove(e) {
        const position = {
            x: e.clientX - positionParent["left"],
            y: e.clientY - positionParent["top"],
        };
        cursor.style.transform = `translate(${position.x}px, ${position.y}px)`;
    }

    function onMouseLeave(e) {
        parent.classList.remove("cursor-off");
    }

    // Img();

    // small funny detail
    // lets see two o in title together
    (function funny() {
        const title = document.querySelector(".coloring__title");
        if (!!title) {
            const titleString = title.innerHTML.toLocaleLowerCase();
            const substring = "oo";
            if (titleString.indexOf(substring) > 0) {
                let newStr = titleString.replace(
                    /oo/i,
                    '<span class="fny">oo</span>'
                );
                title.innerHTML = newStr;
                // console.log(newStr);
                let elem = document.querySelector(".fny");
                elem.addEventListener("click", (e) => {
                    if (e.target == elem) {
                        e.target.classList.add("is-visible");
                        setTimeout(() => {
                            e.target.classList.add("is-animate");
                            setTimeout(() => {
                                randomColor();
                                e.target.classList.remove("is-visible");
                                e.target.classList.remove("is-animate");
                            }, 3000);
                        }, 1400);
                    }
                });
            }
        }
    })();

    window.randomColor = (e) => {
        function getRandomInt(max) {
            return Math.floor(Math.random() * max);
        }
        let path = [...svgPath].filter((e) =>
            e.classList.contains("can-colored")
        );
        path.forEach((e) => {
            e.style.fill = colors[getRandomInt(colors.length)];
        });
    };

    let colorising = localStorage.getItem('colorising');
    if(colorising){
        randomColor();
        localStorage.setItem('lazy', '1');
        localStorage.removeItem('colorising');
    } else {
        localStorage.setItem('lazy', '');
    }
}

async function sendingImg(
    drawedImageRaw,
    drawedImage,
    drawedImageWithBox,
    drawedImageWithBoxSquare,
    drawedImageWithBoxFb,
    drawedImageStory,
    fileName
) {
    if (isLocal) {
        window.location.href = "/color-preview.html";
    }

    const formD = new FormData();

    // formD.append("imageRaw", drawedImageRaw);
    // formD.append("imagePreviewWithoutBox", drawedImage);
    // formD.append("imagePreview", drawedImageWithBox);
    // formD.append("imagePreviewSquare", drawedImageWithBoxSquare);
    // formD.append("imagePreviewFacebook", drawedImageWithBoxFb);
    // formD.append("imagePreviewStory", drawedImageStory);
    formD.append("pdf_filename", fileName);

    const coloringForm = document.querySelector(".color-part-form");

    if (!!coloringForm) {
        const typeName = document.querySelector(
                '[name="name-type"]:checked'
            ).value,
            name = document.getElementsByName("instagram-name")[0].value,
            agreementSharing =
                document.getElementsByName("agreementSharing")[0].value,
            agreementTerms =
                document.getElementsByName("agreementTerms")[0].value;

        // console.log(typeName);
        // console.log(name);
        // console.log(agreementSharing);
        // console.log(agreementTerms);

        formD.append("typeName", typeName);
        formD.append("name", name);
        formD.append("agreementSharing", agreementSharing);
        formD.append("agreementTerms", agreementTerms);
        formD.append("lazy", Boolean(localStorage.getItem("lazy")) ? 1 : 0);
        formD.append("lang", document.documentElement.lang || "nl");
    }

    console.log("start sending form");

    try {
        const response = await axios({
            method: "post",
            url: "/image/upload-image",
            data: formD,
            headers: {
                'Content-Type': `multipart/form-data; boundary=${formD._boundary}`,
            },
        });

        const id = response.data.id;

        await Promise.all(
            [
                ['raw', drawedImageRaw],
                ['preview', drawedImageWithBox],
                ['without-box', drawedImage],
                ['square', drawedImageWithBoxSquare],
                ['story', drawedImageStory],
                ['facebook', drawedImageWithBoxFb]
            ]
                .map(([type, blob]) => new Promise(async (resolve, reject) => {
                    const blobs = splitBlob(blob, 0, 1024 * 1024 * 9 /* 9 megabytes */);

                    try {
                        for (const blob of blobs) {
                            const formData = new FormData();
                            formData.append('file', blob);

                            await axios({
                                method: "post",
                                url: `/image/upload-image/${id}/${type}`,
                                data: formData,
                                headers: {
                                    'Content-Type': `multipart/form-data; boundary=${formData._boundary}`,
                                },
                            });
                        }
                        resolve();
                    } catch {
                        reject()
                    }
                }))
        );

        window.location.href = `/color-preview/${response.data.id}?lang=${document.documentElement.lang}`;
    } catch (err) {
        console.log(err);
    } finally {
        // const token = await grecaptcha.execute(
        //     recaptchaToken,
        //     { action: "home_form" }
        // );
        // document.getElementById(
        //     "g-recaptcha-response-60edd5e3419e6"
        // ).value = token;
    }
}

function getImgFromHtml() {
    const imgCreateWrapper = document.querySelector(".js-img-create");
    const svgElement = document.querySelector(".js-canvas-inner > svg");
    const drawedImg = new Image();
    const drawedImgWithBox = new Image();
    const drawedImageWithBoxSquare = new Image();
    const drawedImageWithBoxFb = new Image();
    const drawedImageWithBoxStory = new Image();
    console.log("before bundle img");
    wrapDrawedImagesWithBox(svgElement).then(
        ({ drawedImageInsta, drawedImageRaw, drawedImage, drawedImageWithBox, drawedImageFb, drawedImageStory, fileName }) => {
            const drawedImageURL = URL.createObjectURL(drawedImage);
            const drawedImageWithBoxURL =
                URL.createObjectURL(drawedImageWithBox);
            const drawedImageWithBoxSquareURL =
                URL.createObjectURL(drawedImageInsta);
            const drawedImageWithBoxFbURL =
                URL.createObjectURL(drawedImageFb);
            const drawedImageWithBoxStoryURL =
                URL.createObjectURL(drawedImageStory);

            drawedImg.onload = () => {
                URL.revokeObjectURL(drawedImageURL);
            };

            drawedImgWithBox.onload = () => {
                URL.revokeObjectURL(drawedImageWithBoxURL);
            };
            drawedImageWithBoxSquare.onload = () => {
                URL.revokeObjectURL(drawedImageWithBoxSquareURL);
            };

            drawedImageWithBoxFb.onload = () => {
                URL.revokeObjectURL(drawedImageWithBoxFbURL);
            };

            drawedImageWithBoxStory.onload = () => {
                URL.revokeObjectURL(drawedImageWithBoxStoryURL);
            };

            drawedImg.src = drawedImageURL;
            drawedImgWithBox.src = drawedImageWithBoxURL;
            drawedImageWithBoxSquare.src = drawedImageWithBoxSquareURL;
            drawedImageWithBoxFb.src = drawedImageWithBoxFbURL;
            drawedImageWithBoxStory.src = drawedImageWithBoxStoryURL;

            // imgCreateWrapper.innerHTML = "";

            // imgCreateWrapper.appendChild(drawedImgWithBox);
            // drawedImg.style.backgroundColor = 'red';
            // imgCreateWrapper.appendChild(drawedImg);

            // imgCreateWrapper.appendChild(drawedImageWithBoxSquare);
            // imgCreateWrapper.appendChild(drawedImageWithBoxFb);
            // imgCreateWrapper.appendChild(drawedImageWithBoxStory);

            sendingImg(drawedImageRaw, drawedImage, drawedImageWithBox, drawedImageInsta, drawedImageFb, drawedImageStory, fileName);
        }
    );
}

const createCanvas = document.querySelector(".js-create-canvas");
if (createCanvas) {
    createCanvas.addEventListener("click", function () {
        const name = document.getElementsByName("instagram-name")[0];
        if (name.value != "") {
            createCanvas.classList.add("in-progress");
            document.body.classList.toggle('show-modal-info');
            getImgFromHtml();
        } else {
            name.classList.add("error");
        }
    });
}

const requiredFields = document.querySelectorAll(".js-no-empty");
if (requiredFields.length > 0) {
    [...requiredFields].forEach((e) => {
        e.addEventListener("focus", function () {
            if (e.classList.contains("error")) {
                e.classList.remove("error");
            }
        });
    });
}

const maxLengthCounter = () => {
    const fields = document.querySelectorAll('input[maxlength]');
    if(fields.length > 0) {
        [...fields].forEach( e=> {
            const count = e.nextElementSibling;
            const maxL = e.getAttribute('maxlength');
            if(count.classList.contains('js-field-symbol-count')){
                const curr = count.querySelector('.current');
                console.log();
                if(!!curr){
                    const currColor = 'var(--color-text)';
                    e.addEventListener('input', function(){
                        e.value = e.value.substr(0,maxL);
                        curr.textContent = e.value.length;
                        if(e.value.length > (maxL - 4) && e.value.length <= (maxL - 1)){
                            count.style.color = 'var(--color-yellow)';
                        } else if(e.value.length > (maxL - 1)) {
                            count.style.color = 'var(--color-primary)';
                        } else {
                            count.style.color = currColor;
                        }
                    })

                }
            }
        })
    }
}

maxLengthCounter();

const placeholderChanger = document.querySelector(".js-change-placeholder");

if (!!placeholderChanger) {
    const placeholderSrc = {
            type_instagramName: document.querySelector(
                ".js-change-placeholder-src .placeholder_type_instagramName"
            ).innerHTML,
            type_singleName: document.querySelector(
                ".js-change-placeholder-src .placeholder_type_singleName"
            ).innerHTML,
            type_facebookName: document.querySelector(
                ".js-change-placeholder-src .placeholder_type_facebookName"
            ).innerHTML,
        },
        placeholderInputs = document.getElementsByName("name-type");
    function nameFixer(){
        const activeRadio = [...placeholderInputs].filter(e=> e.checked);
        if(activeRadio[0].value === 'type_instagramName' || activeRadio[0].value === 'type_facebookName'){
            placeholderChanger.value = '@' + placeholderChanger.value.replace(/\@?/, '')
        }
        if(activeRadio[0].value === 'type_singleName'){
            placeholderChanger.value = placeholderChanger.value.replace(/\@/g, '')
        }
        placeholderChanger.focus()
    }
    [...placeholderInputs].forEach((e) => {
        e.addEventListener("change", (evnt) => {
            // console.log(placeholderSrc[evnt.target.value]);
            placeholderChanger.setAttribute(
                "placeholder",
                placeholderSrc[evnt.target.value]
            );
            nameFixer()
        });
    });

    placeholderChanger.addEventListener("focus", function(){
        nameFixer()
    })
    document.addEventListener("DOMContentLoaded", function() {
        let startPlaceholder = [...placeholderInputs].filter(e => e.checked);
        placeholderChanger.setAttribute(
            "placeholder",
            placeholderSrc[startPlaceholder[0].value]
        );
    });
}

const checkBtnFormStatement = () => {
    const btn = document.querySelector(".js-create-canvas");

    if (!!btn && formAgreementInputs.length > 0) {
        let counter = [...formAgreementInputs].reduce(
            (acc, el) => acc + (el.checked ? 1 : 0),
            0
        );
        if (counter === formAgreementInputs.length) {
            btn.disabled = false;
        } else {
            btn.disabled = true;
        }
    }
};
checkBtnFormStatement();

if (formAgreementInputs.length > 0) {
    [...formAgreementInputs].forEach((e) => {
        e.addEventListener("input", checkBtnFormStatement);
    });
}

const resizeCnv = () => {
    document.addEventListener("DOMContentLoaded", function() {
        // console.log('test resize');
        const cnvWrapper = document.querySelector('.js-cnv');
        const cnvInner = document.querySelector('.js-canvas-inner');

        if (!!cnvWrapper && !!cnvInner) {
            window.dragZoneSvg = interact(cnvInner);
            const position = {
                x: 0,
                y: 0,
                scale: 1
            };

            const btnZoomIn = document.querySelector('.js-svg-zoom-in');
            const btnZoomOut = document.querySelector('.js-svg-zoom-out');

            const imgMoverAndResize = () => {
                cnvInner.style.transform =
                    `translate(${position.x}px, ${position.y}px) scale(${position.scale})`
            }

            btnZoomIn.addEventListener('click', function () {
                position.scale += .1;
                imgMoverAndResize();
            })
            btnZoomOut.addEventListener('click', function () {
                position.x = 0;
                position.y = 0;
                position.scale = 1;
                imgMoverAndResize();
            })


            dragZoneSvg
                .gesturable({
                    listeners: {
                        start(event) {
                        },
                        move(event) {
                            position.scale = position.scale * event.scale
                            imgMoverAndResize()
                        },
                        end(event) {
                            position.scale = position.scale * event.scale
                        }
                    }
                })
                .draggable({
                    inertia: true,
                    modifiers: [
                        interact.modifiers.restrictRect({
                            restriction: 'parent',
                            endOnly: true
                        })
                    ],
                    listeners: {
                        start(event) {
                        },
                        move(event) {
                            position.x += event.dx
                            position.y += event.dy
                            imgMoverAndResize()
                        },
                        end(event) {
                        }
                    }
                })


        }


    })
}

resizeCnv();
