import interact from 'interactjs'
import tippy from 'tippy.js';
import {
    isLocal
} from './services';

const itemsList = document.querySelector('.js-frames-list');
const items = document.querySelectorAll('.js-frame');
const link = document.querySelector('.js-toSelectedFrame');
const wrapper = document.querySelector('.js-frames-wrapper');
const regxp = /frame\d+/;



// const checkBtnNextStatement = () => {
//     if (!!link && !!itemsList) {
//         if (itemsList.classList.contains('child-selected')) {
//             link.classList.remove('is-disabled');
//         } else {
//             link.classList.add('is-disabled');
//         }
//     }
// };
// checkBtnNextStatement();

if (items.length > 0) {
    items.forEach(e => {
        e.addEventListener('click', function () {
            const modal1 = document.querySelector('#popupConfirm');
            if(!!modal1){
                document.body.classList.toggle('show-modal-confirm');
                const radio1 = document.querySelector('#nextPopup'),
                      radio2 = document.querySelector('#closePopup'),
                      confirmBtn = document.querySelector('#popupConfirmBtn');

                localStorage.setItem('popupConfirm', 'true');
                radio1.addEventListener('change', ()=> {
                    localStorage.setItem('popupConfirm', 'true');
                    console.log('radio1')
                })
                radio2.addEventListener('change', ()=> {
                    localStorage.removeItem('popupConfirm');
                    console.log('radio2')
                })

                confirmBtn.addEventListener('click', () => {
                    if (localStorage.getItem('popupConfirm') !== null) {
                        document.body.classList.remove('show-modal-confirm');
                        document.body.classList.add('show-modal-coloring');
                        console.log('yes')
                    } else {
                        document.body.classList.remove('show-modal-confirm');
                        document.body.classList.remove('show-modal-coloring');
                        console.log('no')
                    }
                })
            }
            
            [...items].filter(el => el !== this).forEach(el => {
                el.classList.remove('is-active')
            });
            this.classList.toggle('is-active');
            const artistName = e.dataset.artist;
            const thisIndex = [...items].indexOf(this) + 1;

            const modal2 = document.querySelector('#choiceColoring');
            if(!!modal2){
                const radio1 = document.querySelector('#colorCustom'),
                      radio2 = document.querySelector('#colorAuto');
    
                radio1.addEventListener('change', ()=> {
                    localStorage.removeItem('colorising');
                })
                radio2.addEventListener('change', ()=> {
                    localStorage.setItem('colorising', 'auto');
                })
                document.querySelector(".popup-coloring__overlay").addEventListener("click", ()=> {
                    document.body.classList.remove('show-modal-coloring');
                })
                document.querySelector(".popup-coloring__close").addEventListener("click", ()=> {
                    document.body.classList.remove('show-modal-coloring');
                })
            }


            // console.log(artistName)
            if (!isLocal) {
                if (link.href.search(regxp) > 0) {
                    link.href = link.href.replace(/frame\d+/, `frame${thisIndex}`)
                } else if (link.href.search(/(\?lang=[a-z]{2})/) > 0) {
                    link.href = link.href.replace(/(\?lang=[a-z]{2})/, `/frame${thisIndex}$1`)
                } else {
                    link.href = link.href + `/frame${thisIndex}`
                }
            }

            if(link.href.search(/artist/) > 0){
                link.href = link.href.replace(/artist=.*/g, `artist=${encodeURI(artistName)}`)
            } else {
                link.href = link.href.replace(/(\?lang=[a-z]{2})/g,'') + window.location.search + `&artist=${encodeURI(artistName)}`;
            }

            if ([...items].filter(el => el.classList.contains('is-active')).length > 0) {
                itemsList.classList.add('child-selected');
            } else {
                itemsList.classList.remove('child-selected');
            }


            // checkBtnNextStatement();
        })
    })
}





if (!!itemsList) {
    window.dragZone = interact(itemsList);
    const position = {
        x: 0,
        y: 0,
        scale: 1
    };



    const btnZoomIn = document.querySelector('.js-zoom-in');
    const btnZoomOut = document.querySelector('.js-zoom-out');
    const move = document.querySelector('.js-handler-move');

    // const tippyBlocks = tippy('.js-frame', {
    //     placement: 'top-start',
    //     trigger: 'click',
    //     maxWidth: 320,
    //     content(reference) {
    //         const id = reference.getAttribute('data-template');
    //         const template = document.getElementById(id);
    //         return template.innerHTML;
    //     },
    //     allowHTML: true,
    // });

    itemsList.addEventListener('click', e => {
        if (!!move) {
            if (!move.classList.contains('hidden')) {
                move.classList.add('hidden');
            }
        }
    })


    const imgMoverAndResize = () => {
        // console.log(position.x)
        itemsList.style.transform =
            `translate(${position.x}px, ${position.y}px) scale(${position.scale})`
    }
    const toCenterAndResize = () =>{
        const minScaleY = +(wrapper.offsetHeight / itemsList.offsetHeight).toFixed(2);
        const minScaleX = +(wrapper.offsetWidth / itemsList.offsetWidth).toFixed(2);
        const positionMinScale = Math.min(minScaleY,minScaleX);
        position.scale = positionMinScale;
        position.y = -(itemsList.offsetHeight - (itemsList.offsetHeight * positionMinScale)) / 2;
        position.x = ((wrapper.offsetWidth - (itemsList.offsetWidth - (itemsList.offsetWidth * positionMinScale))) / 2) - ((itemsList.offsetWidth * positionMinScale) / 2);
    }

    btnZoomIn.addEventListener('click', function () {
        position.scale += .1;
        imgMoverAndResize();
    })
    btnZoomOut.addEventListener('click', function () {
        // position.scale -= .1;
        toCenterAndResize();
        imgMoverAndResize();
    })
    toCenterAndResize();
    imgMoverAndResize();


    dragZone
        .gesturable({
            listeners: {
                start(event) {
                    if (!!move) {
                        move.classList.add('hidden');
                    }
                },
                move(event) {
                    position.scale = position.scale * event.scale
                    imgMoverAndResize()
                },
                end(event) {
                    position.scale = position.scale * event.scale
                }
            }
        })
        .draggable({
            inertia: true,
            modifiers: [
                interact.modifiers.restrictRect({
                    restriction: 'parent',
                    endOnly: true
                })
            ],
            listeners: {
                start(event) {
                    // tippyBlocks.forEach(e => e.hide());
                    itemsList.classList.add('is-dragStart');
                    if (!!move) {
                        move.classList.add('hidden');
                    }
                },
                move(event) {
                    position.x += event.dx
                    position.y += event.dy
                    imgMoverAndResize()
                },
                end(event) {
                    itemsList.classList.remove('is-dragStart')
                }
            }
        })


}

